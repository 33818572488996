import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useState } from "react";

import { color3, primary } from "../styles/colors";
import { Popover, Typography } from "@mui/material";
import { Button, Divider, Tooltip } from "@material-ui/core";

const SimplePopover = (props) => {
  const { children } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <span 
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <InfoOutlinedIcon
          style={{
            color: open ? primary : "inherit",
            transform: "scale(0.9)",
            paddingTop: 5,
            cursor: "pointer",
          }}
        />
      </span>
      <Popover
        id={id}
        sx={{ pointerEvents: "none", fontFamily: "Poppins" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
      >
        <Typography 
        sx={{ fontFamily: "Poppins", p: 2 }}>{props.children}</Typography>
      </Popover>
    </div>
  );
};

export default SimplePopover;
